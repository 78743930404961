import React from "react";
import GetStarted from "./GetStarted";

const Banner = () => {
	return (
		<>
			<section className="banner-section relative">
				<div className="container relative z-10">
					<div className="relative">
						<h1 className="title">
							Find flatmates <br /> that{" "}
							<span className="text-purple">actually fit</span>
						</h1>
						<div className="flex flex-wrap gap-4 mt-12 gap-y-7">
							<GetStarted className="max-sm:flex-grow max-sm:justify-center max-sm:max-w-[320px]" />
							<div className="flex flex-wrap gap-4">
								<a
									href="https://apps.apple.com/gb/app/loby/id6737482486"
									target="_blank"
									className="btn p-0 border-0 before:w-full before:h-full"
								>
									<img
										src="/img/app-store.svg"
										className="max-w-full w-[151px]"
										alt=""
									/>
								</a>

								<a
									href="https://play.google.com/store/apps/details?id=com.roomss.loby&pcampaignid=web_share"
									target="_blank"
									className="btn p-0 border-0 before:w-full before:h-full"
								>
									<img
										src="/img/play-store.svg"
										className="max-w-full w-[151px]"
										alt=""
									/>
								</a>
							</div>
						</div>
					</div>
				</div>
				<img
					src="/img/banner-2.png"
					// src="/img/banner.png"
					className="absolute lg:-bottom-7 2xl:-bottom-20 select-none right-0 max-w-[1000px] w-[65vw] max-lg:top-[unset] max-lg:bottom-0 max-lg:translate-y-[0] max-lg:max-w-[unset] max-lg:w-full max-lg:relative max-lg:mt-10 max-md:w-[120%] max-md:translate-x-[-10%]"
					alt=""
				/>
				<img
					src="/img/banner-shape.svg"
					className="absolute top-[180px] max-w-full left-[calc(50%+380px)] max-lg:left-1/2 max-lg:top-[450px]  max-sm:left-[50%] max-sm:w-[50px]"
					alt=""
				/>
				<img
					src="/img/banner-shape-2.svg"
					className="absolute bottom-[180px] max-w-full left-[calc(50%-100px)] max-lg:hidden max-xl:bottom-20"
					alt=""
				/>
			</section>
		</>
	);
};

export default Banner;
